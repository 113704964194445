/* eslint-disable no-template-curly-in-string */
const zh = {
	"photo.selected": "已選 {number} 張",
	"photo.next": "下一步",
	"photo.noPhotos": "尚未上傳照片",
	"source.image": "媒體庫",
	"upload.file": "上傳照片",
	"upload.newest": "最新上傳",
	// action
	"action.cancel": "取消",
	"action.ok": "確定",
	// edit
	"edit.title": "編輯相片",
	"edit.tab.property": "屬性",
	"edit.tab.frame": "相框",
	"edit.tab.text": "文字",
	"edit.tab.location": "地標",
	"edit.tab.date": "日期",
	"edit.frameLoadmore": "更多相框",
	"edit.reset": "重設",
	"edit.layout": "版型",
	"edit.straight": "直式",
	"edit.horizontal": "橫式",
	"edit.full": "滿版",
	"edit.center": "置中",
	"edit.rotate": "旋轉",
	"edit.edit": "編輯",
	"edit.editText": "編輯文字",
	"edit.editLocation": "編輯地標",
	"edit.editDate": "編輯日期",
	"edit.show": "顯示",
	"edit.hide": "隱藏",
	"edit.wordLimit": "字數限制",
	"edit.clear": "清空",
	"edit.progress": "照片輸出中({current}/{total})",
	"edit.disable.frame": "此版型沒有相框",
	"edit.frame.disable.text": "此版型或相框 無法編輯文字",

	// Home
	loadmore: "讀取更多",

	// frame
	"frame.title": "搜尋相框",
	"frame.search": "搜尋",
	"frame.enterKeywords": "輸入關鍵字",
	"frame.hotIllustrators": "熱門插畫家",
	"frame.hotKeywords": "熱門關鍵字",
	"frame.illResult": "插畫家結果",
	"frame.keywordResult": "關鍵字結果",
	"frame.hot": "熱門",
	"frame.new": "最新",
	"frame.random": "隨機",
	"frame.noResult": " 無搜尋結果",
	"frame.popular": "熱門推薦",

	// checkout
	"checkout.title": "結帳",
	"checkout.terms": "服務條款",
	"checkout.payment": "付款方式",
	"checkout.coupon": "優惠序號",
	"checkout.priceTitle": "消費總金額",
	"checkout.price": "{price_unit}{price} / 共{number}張",
	"checkout.serialcode": "列印序號為",
	"checkout.save.serialcode": "請記下此列印序號",
	"checkout.gophotopage": "回到照片頁面",
	"checkout.gohomepage": "回到首頁",
	"checkout.sendMail": "寄送照片電子檔",
	"checkout.enterEmail": "輸入電子信箱",
	"checkout.send": "寄送",
	"checkout.cannot.getEphoto": "需完成付款 才可索取檔案",
	"checkout.emailWrongFormay": "電子信箱格式錯誤",
	"checkout.sendEmailSuccess": "信件傳送完成",
	"checkout.enterText": "輸入文字",
	"checkout.checkout": "結帳",
	"checkout.linepay": "Linepay",
	"checkout.creditcard": "信用卡",
	"checkout.ATM": "ATM轉帳",
	"checkout.onsite": "機台支付",
	"checkout.mp.point": "MP點數",
	"checkout.current.mp.point": "目前點數",
	"checkout.tutorial.step": "步驟",

	// alert
	"alert.reset.title": "重設照片",
	"alert.reset.message": "確定要重設嗎？",
	"alert.select.even.photos": "照片張數請選擇雙數",
	"alert.insufficient.balance": "餘額不足,請先加值",

	//illustrator
	"illustrator.no.frame": "目前尚未有相框",
	"illustrator.no.data": "目前尚未有資訊",
	"illustrator.goto.printphoto": "製作照片",
	"illustrator.goto.location": "機台位置",

	// Footer
	"footer.about": "關於 Memopresso",
	"footer.intro":
		"透過個性化社群軟體與軟硬整合，無人零售的技術，插畫家、創作者合作，提供年輕一代使用者快速保存，製作與分享個性化相片的解決方案，打造“有感社交” 的社群分享體驗。",
	"footer.guideTitle": "網頁導覽",
	"footer.guide.about": "關於",
	"footer.guide.use": "使用說明",
	"footer.guide.location": "機台地點",
	"footer.guide.hotCreator": "熱門創作者",
	"footer.guide.business": "商業合作",
	"footer.guide.lang": "語言",
	"footer.contact.tel": "客服電話: 02-37655060",
	"footer.contact.mail": "客服信箱: business@memopresso.com",

	//Creator
	"creator.title": "創作者專區",
	"creator.search": "搜尋創作者",
	"creator.weekHot": "本週熱門",
	"creator.monthHot": "本月熱門",
	"creator.new": "新進創作",
	"creator.recommend": "推薦專區",
	"creator.copy": "複製連結",
	"creator.copySuccess": "複製成功",
	"creator.shareFacebook": "分享於Facebook",

	//Manual
	"manual.title": "使用說明",
	"manual.step1": "登錄LINE，選相片和相框",
	"manual.step2": "付款並取得序號",
	"manual.step3": "前往附近機台 點擊LINE按鈕並輸入序號",
	"manual.step4": "取得您的照片",
	"manual.detail": "詳細教學",

	// Location
	"location.title": "機台地點",
	"location.openMap": "開啟地圖",
};

export default zh;
