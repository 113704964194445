import { createMuiTheme, fade, darken } from "@material-ui/core/styles";
import palette from "./custom/palette";

// A custom theme
export const Theme = createMuiTheme({
	palette,
	overrides: {
		MuiAppBar: {
			root: {
				color: palette.grey.dark,
			},
			positionFixed: {
				zIndex: 1100,
			},
			colorPrimary: {
				color: palette.common.white,
				backgroundColor: palette.primary.main,
			},
		},
		MuiButton: {
			root: {
				color: palette.grey.dark,
				padding: "6px 16px",
				borderRadius: 9999,
				whiteSpace: "nowrap",
				lineHeight: "20px",
				"&:hover": {
					color: palette.primary.main,
					backgroundColor: fade(
						palette.primary.main,
						palette.action.hoverOpacity
					),
				},
			},
			text: {
				padding: "6px 16px",
			},
			outlined: {
				padding: "5px 16px",
				border: `1px solid ${fade(palette.dark.dark, 0.23)}`,
				"&:hover": {
					color: palette.primary.main,
					border: `1px solid ${palette.primary.main}`,
					backgroundColor: fade(
						palette.primary.main,
						palette.action.hoverOpacity
					),
				},
			},
			outlinedSecondary: {
				color: palette.dark.dark,
				border: `1px solid ${fade(palette.dark.dark, 0.23)}`,
				"&:hover": {
					color: darken(palette.dark.dark, 0.5),
					border: `1px solid ${palette.grey.dark}`,
					backgroundColor: fade(
						palette.grey.dark,
						palette.action.hoverOpacity
					),
				},
			},
			textSecondary: {
				color: palette.dark.dark,
				"&:hover": {
					color: darken(palette.dark.dark, 0.5),
					backgroundColor: fade(
						palette.dark.dark,
						palette.action.hoverOpacity
					),
				},
			},
			contained: {
				color: palette.grey.dark,
				backgroundColor: palette.common.white,
				"&:hover": {
					color: palette.primary.main,
					backgroundColor: palette.common.white,
				},
			},
			containedPrimary: {
				color: palette.common.white,
				"&:hover": {
					color: palette.common.white,
				},
			},
			containedSecondary: {
				backgroundColor: palette.dark.dark,
				"&:hover": {
					color: palette.common.white,
					backgroundColor: darken(palette.dark.dark, 0.5),
				},
			},
			iconSizeMedium: {
				"& > svg": {
					width: 20,
					height: 20,
				},
			},
		},
	},
});

export default Theme;
