import LSKey from "constants/LocalStorageKey";
import { CHANGE_LANG } from "actions/general";

// Languages
const storedLang = localStorage.getItem(LSKey.Language);
const acceptLang = ["en", "zh-Hant-HK"];
const browserLang = navigator.language.split(/[-_]/)[0]; // language without region code
const [defaultLang = "en"] = acceptLang.filter((lang) =>
	lang.includes(browserLang)
);

const defaultState = {
	lang: storedLang || defaultLang,
};

const generalReducer = (state = defaultState, action) => {
	switch (action.type) {
		case CHANGE_LANG: {
			localStorage.setItem(LSKey.Language, action.payload);

			return {
				...state,
				lang: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default generalReducer;
