import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import generalReducer from "reducers/gereral.js";

const createRootReducer = (history, action) =>
	combineReducers({
		router: connectRouter(history, action),
		general: generalReducer,
	});
export default createRootReducer;
