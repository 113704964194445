import React, { useEffect, Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { gaInit, gaPageView } from "utils/GA";

// Components
// const Redirect = lazy(() => import("components/Redirect"));
const Navigation = lazy(() => import("components/navigation/Navigation"));
// const Bestnine = lazy(() => import("components/bestnine/Bestnine"));
// const BestnineShare = lazy(() => import("components/bestnine/BestnineShare"));
const Policy = lazy(() => import("components/Policy"));

const App = () => {
	useEffect(() => {
		gaInit("UA-113783728-2");
		gaPageView();
	}, []);

	return (
		<div
			className="container-fluid"
			style={{
				width: "100vw",
				minHeight: "100vh",
				padding: 0,
				overflow: "hidden",
				backgroundColor: "#F9FBFF",
			}}
		>
			<>
				<Suspense fallback={<div />}>
					<Switch>
						{/* <Route exact path="/" component={Redirect} /> */}
						<Route path="/policy" component={Policy} />
						{/* Legacy Page */}
						{/* <Route path="/bestnine" component={Bestnine} />
						<Route
							path="/bestnineShare"
							component={BestnineShare}
						/> */}
						<Route path="/" component={Navigation} />
					</Switch>
				</Suspense>
			</>

			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				draggable
				pauseOnHover
				style={{ zIndex: 9999 }}
			/>
		</div>
	);
};

export default App;
