/* eslint-disable no-template-curly-in-string */
const en = {
	"photo.selected": "Selected {number} ",
	"photo.next": "Next",
	"photo.noPhotos": "No Photos",
	"source.image": "Image",
	"upload.file": "Upload File",
	"upload.newest": "Newest",
	// action
	"action.cancel": "Cancel",
	"action.ok": "OK",
	// edit
	"edit.title": "Edit Photo",
	"edit.tab.property": "Property",
	"edit.tab.frame": "Frame",
	"edit.tab.text": "Text",
	"edit.tab.location": "Location",
	"edit.tab.date": "Date",
	"edit.frameLoadmore": "More Frames",
	"edit.reset": "reset",
	"edit.layout": "layout",
	"edit.straight": "vertical",
	"edit.horizontal": "horizontal",
	"edit.full": "full",
	"edit.rotate": "rotate",
	"edit.center": "center",
	"edit.edit": "edit",
	"edit.editText": "Edit Text",
	"edit.editLocation": "Edit Location",
	"edit.editDate": "Edit Date",
	"edit.show": "show",
	"edit.hide": "hide",
	"edit.wordLimit": "Word Limit",
	"edit.clear": "Clear",
	"edit.progress": "Processing({current}/{total})",
	"edit.disable.frame": "No frame",
	"edit.frame.disable.text": "Cannot edit with frame",

	// Home
	loadmore: "Load more",

	// frame
	"frame.title": "Search Frames",
	"frame.search": "Search",
	"frame.enterKeywords": "Enter keywords",
	"frame.hotIllustrators": "Hot Illustrators",
	"frame.hotKeywords": "Hot Keywords",
	"frame.illResult": "Illustrator Result",
	"frame.keywordResult": "Keyword Result",
	"frame.hot": "Hot",
	"frame.new": "New",
	"frame.random": "Random",
	"frame.noResult": "No Search Result",
	"frame.popular": "Popular",

	// checkout
	"checkout.title": "Checkout",
	"checkout.terms": "Terms",
	"checkout.payment": "Payment",
	"checkout.coupon": "Coupon",
	"checkout.priceTitle": "Consumption",
	"checkout.price": "{price_unit}{price} /{number} photos",
	"checkout.serialcode": "serial code is",
	"checkout.save.serialcode": "Please save this serial code",
	"checkout.gophotopage": "Go photo page",
	"checkout.gohomepage": "Go home page",
	"checkout.sendMail": "Send Mail",
	"checkout.enterEmail": "Enter Email",
	"checkout.send": "Send",
	"checkout.cannot.getEphoto": "Please complete the payment before requesting the image file",
	"checkout.emailWrongFormay": "Email Wrong Format",
	"checkout.sendEmailSuccess": "Send Email Successfully",
	"checkout.enterText": "Enter Text",
	"checkout.checkout": "Checkout",
	"checkout.linepay": "Linepay",
	"checkout.creditcard": "Credit card",
	"checkout.ATM": "ATM",
	"checkout.onsite": "onsite",
	"checkout.mp.point": "MP POINT",
	"checkout.current.mp.point": "Current points",
	"checkout.tutorial.step": "Step ",

	// alert
	"alert.reset.title": "Reset Photo",
	"alert.reset.message": "Are you sure you want to reset?",
	"alert.select.even.photos": "Please select an even number of photos",
	"alert.insufficient.balance": "Insufficient balance",

	//illustrator
	"illustrator.no.frame": "No frames",
	"illustrator.no.data": "No Data",
	"illustrator.goto.printphoto": "Print Photos",
	"illustrator.goto.location": "Location",

	"footer.about": "About Memopresso",
	"footer.intro":
		"Through distinguished social media and the integration of software and hardware, we adopt unmanned retail technology and furthermore cooperate with illustrators and creators, providing young users with a solution to quickly save, create and share personalized photos as well as creating an apparent social media sharing experience.",
	"footer.guideTitle": "Guide",
	"footer.guide.about": "About",
	"footer.guide.use": "How to use",
	"footer.guide.location": "Machine Loc",
	"footer.guide.hotCreator": "Hot Creator",
	"footer.guide.business": "Business",
	"footer.guide.lang": "Language",
	"footer.contact.tel": " ",
	"footer.contact.mail": " ",

	//Creator
	"creator.title": "Creators",
	"creator.search": "Search creators",
	"creator.weekHot": "Week Hot",
	"creator.monthHot": "Month Hot",
	"creator.new": "New",
	"creator.recommend": "Recommend",
	"creator.copy": "Copy Link",
	"creator.copySuccess": "Copy Success",
	"creator.shareFacebook": "Share to Facebook",

	//Manual
	"manual.title": "How to use",
	"manual.step1": "Log in to LINE, select photos and frames",
	"manual.step2": "Make payment and get serial number",
	"manual.step3": "Go to the nearby machine and enter the serial number",
	"manual.step4": "Get your photo",
	"manual.detail": "Detail Tutorial",

	// Location
	"location.title": "Machine Location",
	"location.openMap": "Open map",
};

export default en;
