const ENV = {
	isDev: process.env.NODE_ENV !== "production",
	isProd: process.env.NODE_ENV === "production",
};

const Config = {
	MEMO_URL: `https://data.memopresso.com/api`,
	LINE_ID: "1653956261-5JXZ44DZ",
	LINE_DEV_ID: "1653956261-WgX5BBq5",
};

export { ENV };
export default Config;
