import _ from "lodash";
import * as ChangeCase from "change-case";
import { ENV } from "../constants/Config";

const func = (props) => props;

const Helper = {
	ClearSpecialCharacter: (str = "") => {
		return str.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "");
	},

	/**
	 * @param {string} title
	 * @param {Array|Object|string} log
	 */
	Print: (title, log = []) => {
		if (ENV.isDev) {
			const logs = _.isArray(log) ? log : [log];
			console.group(`${title}`);
			logs.forEach((el) => console.log(el));
			console.groupEnd();
			console.log("\n");
		}
	},

	/**
	 * 更改Object propName. ex: 'camelCase', 'snakeCase', ...
	 * @param {object} obj
	 * @param {string} funcName - function name
	 * @param {function} adapterFunc - (key, value) => newValue
	 * @param {boolean} deepChange - 深層改變名稱
	 * @returns {object}
	 */
	ChangeCase: (data, funcName, adapterFunc, deepChange) => {
		// value 轉換器
		const adapter = _.isFunction(adapterFunc)
			? adapterFunc
			: (key, value) => value;

		// 指定的case function
		const caseName = funcName || "camelCase";

		// 限定 array or object
		if (!_.isObject(data)) return data;

		if (_.isArray(data)) {
			return data.map((item) =>
				Helper.ChangeCase(item, caseName, adapter, deepChange)
			);
		}

		const newObj = {};
		// Loop Object
		Object.keys(data).forEach((key) => {
			// key 轉換case
			const propName = ChangeCase[caseName](key);
			const value = data[key];
			let processedValue = adapter(propName, value);
			// value轉換 fallback
			if (_.isNil(processedValue)) {
				processedValue = value;
			}
			newObj[propName] = deepChange
				? Helper.ChangeCase(
						processedValue,
						caseName,
						adapter,
						deepChange
				  )
				: processedValue;
		});

		return newObj;
	},

	NumberWithCommas: (number) => {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	},

	// 螢幕尺寸
	GetScreenSize: () => {
		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;
		const height =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		return {
			width,
			height,
		};
	},
};

export default Helper;
