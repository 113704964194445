import ReactGA from "react-ga";

export const Category = {};

export const Action = {};

export const gaInit = (trackingID) => {
	ReactGA.initialize(trackingID);
};

export const gaPageView = () => {
	ReactGA.pageview(window.location.pathname + window.location.search);
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 */
export const gaEvent = (category, action, label, value) => {
	ReactGA.event({
		category,
		action,
		label: `${label}`,
		value,
	});
};
